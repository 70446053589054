
import {computed, defineComponent} from "vue";
import ListHeader3 from "@/components/common/ListHeader3.vue"
import DetailHeader from "@/components/hotel/DetailHeader.vue"
import {useRoute} from "vue-router";


export default defineComponent({
  name: "kt-hotel",
  components: {
    ListHeader3,
    DetailHeader,
  },
  data() {
    return {
      listSearchValue: "A",
      listSearchCountry: "TR",
    }
  },
  methods: {
    setHeaderValue(e) {
      this.listSearchValue = e;
    },
    setHeaderCountry(e) {
      this.listSearchCountry = e;
    },
  },
  setup() {
    const route=useRoute();
    const path = computed(() =>route.name);

    const headerShow = computed(() => {
      return  (path.value== 'hotellist') ? true : false;
    });

    const detailHeader = computed(() => {
      return  (path.value != 'hotellist') ? true : false;
    });

    return {
      open,
      headerShow,
      detailHeader,
      path
    }
  },
});
