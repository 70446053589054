import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListHeader3 = _resolveComponent("ListHeader3")!
  const _component_DetailHeader = _resolveComponent("DetailHeader")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.headerShow)
      ? (_openBlock(), _createBlock(_component_ListHeader3, {
          key: "header3",
          onSetValue: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setHeaderValue($event))),
          onSetCountry: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setHeaderCountry($event)))
        }))
      : (_openBlock(), _createBlock(_component_DetailHeader, { key: "headerDetails" })),
    _createVNode(_component_router_view, {
      setValue: _ctx.listSearchValue,
      setCountry: _ctx.listSearchCountry
    }, null, 8, ["setValue", "setCountry"])
  ], 64))
}