
import {computed, defineComponent} from "vue";
import Dropdown from "@/components/hotel/Menu.vue";
import store from "@/store";

export default defineComponent({
  name: "kt-account",
  components: {
    Dropdown,
  },
  methods: {
  },
  setup(){
    const myDetails = computed(() => {
      return store.getters.hotelDetails;
    });
    return {
      myDetails,
    };
  }
});
