
import {computed, defineComponent} from "vue";
import store from "@/store";

export default defineComponent({
  name: "dropdown-3",
  components: {},
  setup(){
    const myDetails = computed(() => {
      return store.getters.hotelDetails;
    });
    return {
      myDetails,
    };
  }
});
